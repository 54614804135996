import { Redirect } from 'react-router';
import { PATH_AUTH } from '../routes/paths';

export default function AuthProtect({ children }) {
  const token = localStorage.getItem('access-token');

  if (!token) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
