import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  token: '',
  role: '',
  is_verified: false,
  _id: '',
  name: '',
  email: '',
  phone_number: '',
  location: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser(state, action) {
      const user = action.payload;
      state.token = user.token;
      state.role = user.user.role;
      state.is_verified = user.user.is_verified;
      state._id = user.user._id;
      state.name = user.user.name;
      state.email = user.user.email;
      state.phone_number = user.user.phone_number;
      state.location = user.user.location;
      localStorage.setItem('access-token', user.token);
    },
    logoutUser(state) {
      state = {
        token: '',
        role: '',
        is_verified: false,
        _id: '',
        name: '',
        email: '',
        phone_number: '',
        location: ''
      };
    },
    storeUserId(state, action) {
      state._id = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { loginUser, logoutUser, storeUserId } = slice.actions;
